// stores/useAuthStore.js
import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    authToken: localStorage.getItem('authToken') || null,
  }),
  getters: {
    isLoggedIn: (state) => !!state.authToken,
    token: (state) => state.authToken,
  },
  actions: {
    setToken(token) {
      this.authToken = token;
      localStorage.setItem('authToken', token)
    },
    clearToken() {
      this.authToken = null;
      localStorage.removeItem('authToken');
    },
    login(token) {
      this.setToken(token);
      // Additional login logic can go here
    },
    logout() {
      this.clearToken();
      // Additional logout logic can go here, like redirecting users
      window.location.href='/';
    }
  }
});
