<template>
  <div class="image-uploader">
    <input type="file" accept="image/*" capture="camera" hidden @change="handleFileChange" ref="fileInput"
      :disabled="loading" />
    <div class="bg-green-500 rounded-full w-32 h-32 flex justify-center items-center cursor-pointer"
      @click="triggerCamera">
      <CameraIcon class="text-white size-16" />
    </div>
  </div>

  <v-snackbar v-model="toastMsg" :timeout="3000" location="top right" color="red" text-color="white" elevation="3">
    {{ toastMsg }}
    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="toaster = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref } from "vue";
import { post } from "@/api/axoisInstance";
import { CameraIcon } from "@heroicons/vue/24/solid";

export default {
  name: "WeedImageUploader",
  components: {
    CameraIcon,
  },
  props: {
    onUploadComplete: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const fileInput = ref(null);
    const base64Image = ref(null);
    const toastMsg = ref(null)

    const triggerCamera = () => {
      if (fileInput.value) {
        fileInput.value.click();
      } else {
        console.error("The file input is not accessible.");
      }
    };

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file && file.type.startsWith("image/")) {
        compressAndUploadImage(file);
      }
    };

    const compressAndUploadImage = (file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800;
          const maxHeight = 800;
          let width = img.width;
          let height = img.height;
          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }
          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(img, 0, 0, width, height);

          base64Image.value = canvas.toDataURL(file.type, 0.8);

          post(
            "/identify",
            { image: base64Image.value },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          ).then((res) => {

            if (res.status === 202) {
              toastMsg.value = "Error: Image is not clear. Please try again.";
            } else {
              props.onUploadComplete();
            }
          });
        };
      };
    };

    return {
      fileInput,
      triggerCamera,
      handleFileChange,
      toastMsg
    };
  },
};
</script>

<style scoped>
.image-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>