<template>
  <div class="flex flex-col gap-1 max-w-[413px]">
    <div>
      <v-img :src="img" :max-width="413" :max-height="1930" />
    </div>
    <div class="flex flex-row justify-end gap-2 items-center">
      <p class="text-secondary-600 font-bold">{{ date }}</p>
      <p class="text-secondary-400 text-sm">{{ time }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, defineProps, computed } from "vue";

const props = defineProps({
  img: {
    type: String,
  },
  createdAt: {
    type: String,
  },
});

defineComponent({
  name: "PhotoCard",
});

const date = computed(() => {
  const date = new Date(props.createdAt);
  return `${date.getDate()} - ${date.getMonth()} - ${date.getFullYear()}`;
});

const time = computed(() => {
  const date = new Date(props.createdAt);
  return `${date.getHours()}:${date.getMinutes()}`;
});
</script>
