<template>
  <VApp>
    <div class="min-h-screen flex flex-col">
      <!-- Navigation Bar -->

      <template v-if="!isAuthRoute">
        <div class="bg-black min-h-screen text-white">
          <router-view></router-view>
        </div>
      </template>

      <template v-else>
        <div class="flex flex-row flex-1">
          <AppSidebar
            v-if="authStore.isLoggedIn"
            v-model:isSidebarOpen="isSidebarOpen"
            @toggleSidebar="toggleSidebar"
          />

          <div class="flex flex-col !h-full flex-1 bg-blue-500 max-w-[100vw]">
            <div
              class="min-h-[84px] w-full bg-white flex flex-row items-center justify-between px-8"
            >
              <div>
                <div
                  class="lg:hidden"
                  @click="toggleSidebar"
                  :class="{ hidden: isSidebarOpen }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="black"
                    class="w-8 h-8 text-white cursor-pointer"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4 6h16M4 12h16m-7 6h7"
                    />
                  </svg>
                </div>
              </div>

              <div>
                <!-- Search Bar -->
              </div>

              <div
                class="flex flex-row gap-5 h-full items-center justify-center"
              >
                <v-img :width="40" :height="40" :src="BellIcon"></v-img>
                <HeaderMenuDropdown />
              </div>
            </div>

            <VMain class="flex-1 bg-[#F0F4F4]">
              <div class="p-8">
                <router-view></router-view>
              </div>
            </VMain>
          </div>
        </div>
      </template>
    </div>
  </VApp>
</template>

<script setup>
import { ref } from "vue";
import { useAuthStore } from "./stores/authStore"; // Ensure path to store is correct
import AppSidebar from "./components/Layout/AppSidebar.vue";
import BellIcon from "./assets/icons/bell.svg";
import { useRouter } from "vue-router";
import { computed } from "vue";
import HeaderMenuDropdown from "./components/HeaderMenuDropdown.vue";
import { onMounted, onBeforeUnmount } from "vue";

const isSidebarOpen = ref(window.innerWidth >= 1024);

const router = useRouter();

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
  console.log(isSidebarOpen.value);
};

const updateSidebarState = () => {
  isSidebarOpen.value = window.innerWidth >= 1024;
};

onMounted(() => {
  window.addEventListener("resize", updateSidebarState);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", updateSidebarState);
});

const isAuthRoute = computed(
  () => router.currentRoute.value.meta.requiresAuth || false
);

const authStore = useAuthStore();
</script>
