<template>
  <div class="flex min-h-screen overflow-auto">
    <!-- Left side - Carousel -->
    <div class="hidden md:block w-[60%] relative">
      <div class="relative h-full">
        <div class="absolute inset-0 bg-teal-700 bg-opacity-30"></div>
        <div class="w-full h-full">
          <img
            :src="currentImage"
            alt="Carousel Image"
            class="object-cover w-full h-full"
          />
        </div>
        <div
          class="flex w-full justify-center flex-col items-center absolute bottom-10 gap-10"
        >
          <div class="text-white w-[500px]">
            <p class="text-lg italic mb-4">
              "Lorem ipsum dolor sit amet consectetur. Quis et pellentesque leo
              morbi. Malesuada eget sit imperdiet eget tempus ut."
            </p>
            <div class="flex items-center">
              <img
                src="https://randomuser.me/api/portraits/men/32.jpg"
                alt="Profile"
                class="w-10 h-10 rounded-full"
              />
              <div class="ml-3">
                <p class="text-sm font-semibold">Martin Guptill</p>
                <p class="text-xs text-gray-300">Los Angeles</p>
              </div>
            </div>
          </div>
          <div class="flex space-x-2">
            <span
              v-for="(image, index) in images"
              :key="index"
              class="w-3 h-3 rounded-full"
              :class="{
                'bg-white': index === currentImageIndex,
                'bg-gray-500': index !== currentImageIndex,
              }"
            ></span>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side - Login Form -->
    <div class="flex items-center justify-center w-full md:w-[40%] bg-white">
      <div class="w-full max-w-md p-8">
        <h2 class="text-2xl font-bold text-gray-800 mb-6">
          Login to Turf Planner
        </h2>
        <form @submit.prevent="submitForm">
          <div class="mb-4">
            <label class="block text-gray-700">Email</label>

            <input
              type="email"
              id="email"
              v-model="email"
              required
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>
          <div class="mb-6">
            <label class="block text-gray-700">Password</label>

            <input
              type="password"
              id="password"
              v-model="password"
              required
              class="w-full p-3 border border-gray-300 rounded focus:outline-none focus:border-teal-500"
            />
          </div>
          <button
            type="submit"
            class="w-full bg-teal-500 text-white p-3 rounded mb-4"
          >
            Login
          </button>
          <div class="flex items-center justify-center mb-4">
            <button
              class="flex items-center justify-center bg-primary-100 text-primary-600 w-full p-3 rounded"
            >
              <img
                src="https://img.icons8.com/color/48/000000/google-logo.png"
                class="w-6 h-6 mr-2"
              />
              Continue with Google
            </button>
          </div>
          <div class="text-center">
            <router-link to="/signup" class="text-sm text-secondary-600"
              >Don't have an account?
              <span class="text-primary-600 font-semibold"
                >Register</span
              ></router-link
            >
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/authStore"; // Ensure the path is correct
// Ensure path to store is correct
import { useRouter } from "vue-router"; // Assuming you're using axios for the post request
import { post } from "@/api/axoisInstance";

export default {
  name: "LoginView",

  setup() {
    const authStore = useAuthStore();
    const router = useRouter(); // Get access to the router instance
    const email = ref("");
    const password = ref("");

    const currentImageIndex = ref(0);
    const images = [
      "https://images.unsplash.com/photo-1719937050814-72892488f741?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://plus.unsplash.com/premium_photo-1710385174244-78d7f68ae13d?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      "https://images.unsplash.com/photo-1719937050814-72892488f741?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    ];

    const currentImage = computed(() => {
      return images[currentImageIndex.value];
    });

    onMounted(() => {
      setInterval(() => {
        currentImageIndex.value = (currentImageIndex.value + 1) % images.length;
      }, 3000);
    });

    const submitForm = () => {
      post("/login", {
        email: email.value,
        password: password.value,
      })
        .then((response) => {
          console.log("Login successful:", response.data);
          authStore.login(response.data.token); // Use the login action from your store
          router.push("/dashboard"); // Use router instance from useRouter
        })
        .catch((error) => {
          console.error("Login failed:", error.response.data);
          email.value = "";
          password.value = "";
          alert(
            "Unable to login. Please check your credentials and try again."
          );
        });
    };

    return {
      email,
      password,
      currentImageIndex,
      images,
      currentImage,
      submitForm,
    };
  },
};
</script>
