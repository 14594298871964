import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/stores/authStore';  // Import your auth store
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import PricingView from '../views/PricingView.vue';
import SignupView from '../views/SignupView.vue';  // Ensure this view is correctly imported
import DashboardView from '../views/DashboardView.vue'; // Ensure this view is correctly imported
import WeedsView from '../views/WeedsView.vue';  // Make sure the import path is correct
import WeedsDetails from '../views/WeedsDetails.vue';  // Make sure the import path is correct
import MeasureView from '../views/MeasureView.vue';  // Make sure the import path is correct
import MaintenanceView from '@/views/MaintenanceView.vue';
import YardView from '@/views/YardView.vue';
import PhotoJournalView from '@/views/PhotoJournalView.vue';

const routes = [
  {
    path: '/weeds',
    name: 'Weeds',
    component: WeedsView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/signup',
    name: 'Signup',
    component: SignupView
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: PricingView
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/measure',
    name: 'Measure',
    component: MeasureView,
    meta: { requiresAuth: true } 
  },
  {
    path: '/details/:id',
    name: 'WeedDetails',
    component: WeedsDetails,
    props: true,
    meta: { requiresAuth: true } 
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: MaintenanceView,
    meta: { requiresAuth: true }
  },
  {
    path: '/yard',
    name: 'Yard',
    component: YardView,
    meta: { requiresAuth: true }
  },
  {
    path: '/photo-journal',
    name: 'PhotoJournal',
    component: PhotoJournalView,  
    meta: { requiresAuth: true }
  }
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();  // Use your auth store
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the route requires authentication
    if (!authStore.isLoggedIn) {
      // Redirect to login page if not logged in
      next({
        path: '/login',
        query: { redirect: to.fullPath }  // Pass the full path for redirect after login
      });
    } else {
      next();  // User is logged in, proceed to route
    }
  } else {

    if( to.path =='/' && authStore.isLoggedIn) {
      next({
        path: '/dashboard',
      });
    } 


    // Route does not require auth, always allow
    next();
  }
});


export default router;
