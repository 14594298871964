import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import router from './router';
import './assets/styles/tailwind.css';
import { vuetify } from './plugins/Vuetify';


const app = createApp(App);
const pinia = createPinia();
app.use(vuetify);
app.use(pinia);
app.use(router);
app.mount('#app');


console.log(router); 