<template>
  <div class="field-container">
    <div class="field-row">
      <p class="text-white text-2xl font-bold">
        My Yard
        <span
          class="text-[16px] underline underline-offset-2 cursor-pointer"
          @click="router.push('/yard')"
          >Edit</span
        >
      </p>

      <div class="flex-card-container">
        <div class="field-card">
          <p class="title">Area (sqft)</p>
          <p class="value">{{ yard?.sqft || "-" }}</p>
        </div>
        <div class="field-card">
          <p class="title">Grass Type</p>
          <p class="value">{{ yard?.grass_type || "-" }}</p>
        </div>
        <div class="field-card">
          <p class="title">State</p>
          <p class="value">{{ yard?.state?.state || "-" }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { getYard } from "@/api/yard";
import { useRouter } from "vue-router";

const yard = ref(null);
const router = useRouter();

onMounted(async () => {
  const data = await getYard();
  console.log(data);
  yard.value = Array.isArray(data) ? data[0] : data;
});
</script>

<style scss scoped>
.field-container {
  @apply p-4 w-full min-h-[298px] rounded-[16px] bg-red-500 flex flex-row flex-wrap justify-center items-end;

  background: url("@/assets/background/field-bg.jpeg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.field-row {
  @apply w-full max-w-[865px] min-h-[164px] p-4 rounded-[16px] justify-center flex flex-col gap-3 flex-wrap;
  background: hsla(0, 0%, 100%, 0.2);
}

.flex-card-container {
  @apply w-full flex flex-row gap-4 justify-center flex-wrap;
}

.field-card {
  @apply w-full max-w-[250px] h-[76px] border border-[color:var(--Primary-50,#E6F6F3)] px-4 rounded-[8px] flex flex-col justify-center;
  background: hsla(0, 0%, 100%, 0.6);

  .title {
    @apply text-secondary-600 text-[18px] font-normal;
  }

  .value {
    @apply text-secondary-600 font-bold text-[20px] leading-[27px];
  }
}
</style>
