import { get, post } from "./axoisInstance";

export const getMaintenanceLogs = async () => {
  try {
    const response = await get("maintenance");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const createMaintenanceLog = async (data) => {
  try {
    const response = await post("maintenance", data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDaysSinceFertilization = async () => {
  try {
    const response = await get("/days-since-fertilization"); // Assuming you have the axios base URL set up
    return response.data; // Return the full response data object
  } catch (error) {
    console.log(error);
    throw error;
  }
};