<template>
  <div class="max-w-[100%]">
    <h1 class="heading mb-5">Maintenance</h1>
    <div class="bg-white rounded-xl">
      <div
        class="flex flex-row justify-between items-center gap-3 py-2 px-5 flex-wrap"
      >
        <div>
          <h3 class="subTitle">Logs Maintenance</h3>
        </div>
        <CreateMaintenanceLog @log-created="getLatestMaintenanceLogs" />
      </div>
      <div class="overflow-x-auto">
        <table class="w-full table-auto shadow-2xl">
          <thead>
            <tr class="bg-gray-100">
              <th class="px-5 py-2 text-left">Product Name</th>
              <th class="px-5 py-2 text-left">Quantity</th>
              <th class="px-5 py-2 text-left">Date Applied</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="log in maintenanceLogs" :key="log.name" class="border-t">
              <td class="px-5 py-2">{{ log.treatment }}</td>
              <td class="px-5 py-2">{{ log.amount }}</td>
              <td class="px-5 py-2">{{ log.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import CreateMaintenanceLog from "@/components/Maintenance/CreateMaintenanceLog.vue";
import { getMaintenanceLogs } from "@/api/maintenance";
import { ref, onMounted } from "vue";

const maintenanceLogs = ref([]);

const getLatestMaintenanceLogs = async () => {
  try {
    maintenanceLogs.value = await getMaintenanceLogs();
  } catch (error) {
    console.log("Failed to fetch maintenance logs:", error);
  }
};

onMounted(async () => {
  getLatestMaintenanceLogs();
});
</script>
