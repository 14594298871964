<template>
  <div class="min-h-screen">
    <!-- Hero Section -->
    <div class="flex flex-col justify-center items-center text-center min-h-screen bg-blue-500 text-white p-20">
      <div>
        <h2 class="text-3xl font-bold mb-2">Manage Your Yard with Ease</h2>
        <p class="mb-4">Discover how TurfPlanner can help you maintain the perfect yard.</p>
        <router-link to="/signup" class="inline-block bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full">Sign Up Now</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
