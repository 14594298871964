import { get, post } from "./axoisInstance";

export const createYard = async (data) => {
  try {
    const response = await post("yards", data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getYard = async () => {
  try {
    const response = await get("yards");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};


export const getStates = async () => {
  try {
    const response = await get("states");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
}