<template>
  <div class="flex flex-col gap-5">
    <h1 class="heading">My Yard</h1>
    <div class="flex flex-col gap-5">
      <div class="max-w-[300px]">
        <v-select
          v-model="selectedGrassType"
          :items="grassTypes"
          label="Grass Type"
          outlined
        ></v-select>
        <v-select
          v-model="selectedState"
          :items="states"
          label="State"
          item-title="state"
          item-value="id"
          outlined
        ></v-select>
      </div>
      <div class="flex flex-row gap-5 items-center" v-if="yard?.sqft">
        <p>
          Current Yard Square Feet:
          <span class="font-medium text-lg">{{ yard?.sqft }} ft²</span>
        </p>
        <div
          @click="measurePageRoute"
          class="text-primary-400 border-b border-red-400 p-2 cursor-pointer hover:shadow rounded"
        >
          {{ yard?.sqft ? "Re-Measure" : "Measure" }}
        </div>
      </div>

      <div v-if="!yard?.sqft" class="flex flex-roq gap-5 items-center">
        <p>Please Measure your Yard first</p>
        <div
          @click="measurePageRoute"
          class="text-primary-400 border-b border-red-400 p-2 cursor-pointer hover:shadow rounded max-w-[100px]"
        >
          Measure
        </div>
      </div>

      <div>
        <AppButton @click="updateYardDetails"> Update Yard Details </AppButton>
      </div>
    </div>
  </div>

      <AppToast
      :message="snackbarMessage"
      :visible="snackbarVisible"
      @update:visible="snackbarVisible = $event"
    />
</template>

<script setup>
import { getYard } from "@/api/yard";
import { onMounted, watch, ref } from "vue";
import { grassTypes } from "@/constant/AppConstant";
import AppButton from "@/components/Base/AppButton.vue";
import { useRouter } from "vue-router";
import { getStates, createYard } from "@/api/yard";
import AppToast from "@/components/Base/AppToast.vue";


const snackbarVisible = ref(false);
const snackbarMessage = ref("");
const router = useRouter();
const selectedGrassType = ref(grassTypes[0]);
const selectedState = ref("");

const yard = ref(null);
const states = ref([]);

const measurePageRoute = () => {
  router.push("/measure");
};

const updateYardDetails = () => {
  createYard({
    grass_type: selectedGrassType.value,
    state_id: selectedState.value,
  }).then(() => {
    snackbarMessage.value = "Yard Details Updated";
    snackbarVisible.value = true;  })
};

watch(yard, (yard) => {
  selectedState.value = yard?.state_id;
  selectedGrassType.value = yard?.grass_type;
});

onMounted(async () => {
  const yardData = await getYard();
  yard.value = Array.isArray(yardData) ? yardData[0] : yardData;

  const statesData = await getStates();
  states.value = statesData;
});
</script>
