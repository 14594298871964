<template>
  <div>
    <h1 class="heading mb-5">Photo Journal</h1>

    <div class="card flex flex-col gap-2">
      <div class="flex flex-row justify-between items-center">
        <h3 class="subTitle">Photos</h3>
        <AppButton @click="openDialog" class="px-6 py-2">Add Photo</AppButton>
      </div>
      <hr />

      <div class="flex flex-col gap-4">
        <p class="font-semibold text-secondary-500">Recent</p>
        <div class="flex flex-row gap-4">
          <div v-for="photo in photos" :key="photo.id">
            <PhotoCard :img="photo.url" :createdAt="photo.created_at" />
          </div>
        </div>
      </div>
    </div>

    <AppModal
      :isVisible="dialog"
      title="Upload Photo"
      :showFooter="false"
      @close="dialog = false"
      size="md"
    >
      <div class="h-[380px] max-h-none">
        <FileUpload
          :fileType="'image/*'"
          :showFile="true"
          :action="uploadPhotoHandler"
        />
      </div>
    </AppModal>
  </div>
  <AppToast
    :message="snackbarMessage"
    :visible="snackbarVisible"
    @update:visible="snackbarVisible = $event"
  />
</template>

<script setup>
import { ref, onMounted } from "vue";
import PhotoCard from "../components/PhotoJournal/PhotoCard.vue";
import AppButton from "@/components/Base/AppButton.vue";
import FileUpload from "@/components/FileUpload.vue";
import { getPhotos } from "@/api/photos";
import AppModal from "@/components/Base/AppModal.vue";
import { uploadPhoto } from "@/api/photos";
import AppToast from "@/components/Base/AppToast.vue";

const snackbarVisible = ref(false);
const snackbarMessage = ref("");

const photos = ref([]);
const dialog = ref(false);
const base64Image = ref(null);

const openDialog = () => {
  dialog.value = true;
};

const uploadPhotoHandler = async (file) => {
  if (file) {
    const reader = new FileReader();
    reader.onloadend = () => {
      base64Image.value = reader.result.split(",")[1];
      uploadPhoto({ photo: base64Image.value }).then(() => {
        getPhotosContent();

        snackbarMessage.value = "Photo uploaded successfully";
        snackbarVisible.value = true;
      });
    };
    reader.readAsDataURL(file);
  }
  dialog.value = false;
};

const getPhotosContent = () => {
  getPhotos().then((response) => {
    photos.value = response?.photos;
  });
};

onMounted(() => {
  getPhotosContent();
});
</script>
