import { get } from "./axoisInstance";

export const getCurrentWeather = async (lng, lat) => {
  try {
    const response = await get("weather-forecast", {
      lng,
      lat,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getGroundTemperature = async (lng, lat) => {
  try {
    const response = await get(`ground-temprature`, {
      lng,
      lat,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
