<template>
    <v-snackbar v-model="localVisible" :color="color" :timeout="timeout">
      {{ message }}
    </v-snackbar>
  </template>
  
  <script setup>
  import { ref, watch, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    message: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      default: 'success',
    },
    timeout: {
      type: Number,
      default: 3000,
    },
    visible: {
      type: Boolean,
      required: true,
    }
  });
  
  const emit = defineEmits(['update:visible']);
  
  // Local state for managing visibility
  const localVisible = ref(props.visible);
  
  // Watch the prop to sync with local state
  watch(() => props.visible, (newVal) => {
    localVisible.value = newVal;
  });
  
  // Emit the updated visibility state when it changes
  watch(localVisible, (newVal) => {
    emit('update:visible', newVal);
  });
  
  </script>
  