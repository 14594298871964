<template>
  <div class="card col-span-2 sm:col-span-1 lg:min-h-[132px] flex flex-col justify-center gap-5 px-4 py-3 lg:px-5">
    <p class="text-secondary-500 font-bold">{{ title }}</p>

    <div class="flex flex-row justify-between items-center">
      <div class="flex flex-row gap-1 lg:items-end">
        <p class="text-secondary-900 text-[20px] leading-[20px] md:text-[28px] md:leading-[28px] font-bold">
          <template v-if="days !== null">
            {{ days }}
          </template>
          <template v-else>
            <SpinnerIcon />
          </template>
        </p>
        <p class="font-normal text-sm text-secondary-500">Days</p>
      </div>

      <v-img :src="icon" :max-width="24" :height="24" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import SpinnerIcon from '@/components/SpinnerIcon.vue'; // Assuming SpinnerIcon is in a separate file

export default defineComponent({
  name: 'OverviewCard',
  components: {
    SpinnerIcon,
  },
  props: {
    title: String,
    days: Number, // Assuming days can be null when loading
    icon: String,
  },
});
</script>
