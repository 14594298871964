<template>
  <div v-if="tasks.length" class="w-full flex flex-col gap-6">
    <h3 class="subTitle">Monthly Checklist</h3>

    <v-tabs v-model="tab" class="text-white rounded-lg bg-primary-500">
      <v-tab value="one" class="d-flex align-center justify-center pa-2">
        <v-icon icon="mdi-format-list-bulleted" class="mr-2"></v-icon>
        Pending Tasks
      </v-tab>
      <v-tab value="two" class="d-flex align-center justify-center pa-2">
        <v-icon icon="mdi-check-circle" class="mr-2"></v-icon>
        Completed
      </v-tab>
    </v-tabs>

    <div class="rounded-[16px]">
      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="one">
          <div class="card py-4">
            <div class="flex flex-col gap-3">
              <div
                v-for="task in pendingTasks"
                :key="task.task_priority_id"
                class="flex flex-row gap-2 border-b"
                :class="
                  isTaskDone(task)
                    ? 'bg-primary-400 text-white'
                    : 'bg-white text-black'
                "
              >
                <v-checkbox
                  :model-value="isTaskDone(task)"
                  :label="task.task_name"
                  class="p-0 font-semibold"
                  hide-details
                  @change="() => changeTaskStatus(task)"
                ></v-checkbox>
              </div>
            </div>
          </div>
        </v-tabs-window-item>

        <v-tabs-window-item value="two">
          <div class="card py-4 min-h-[300px] flex h-full">
              <div
                v-if="!completedTasks.length"
                class="flex-1 text-center flex items-center justify-center min-h-full text-secondary-500"
              >
                <p class="font-semibold">No tasks completed yet</p>
              </div>
              <div
                v-for="task in completedTasks"
                :key="task.task_priority_id"
                class="flex flex-row gap-2 border-b border-b-primary-600"
              >
                <v-checkbox
                  :model-value="isTaskDone(task)"
                  :label="task.task_name"
                  class="p-0 font-semibold text-primary-500"
                  color="primary-500"
                  hide-details
                  @change="() => changeTaskStatus(task)"
                ></v-checkbox>
              </div>
          </div>
        </v-tabs-window-item>
      </v-tabs-window>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, computed } from "vue";
import { getTasks } from "@/api/tasks";
import { createMaintenanceLog } from "@/api/maintenance";
import { defineEmits } from "vue";

const emit = defineEmits(["logCreated"]);

const tab = ref("one");
const tasks = ref([]);

const pendingTasks = computed(() =>
  tasks.value.filter((task) => task.status !== "done")
);

const completedTasks = computed(() =>
  tasks.value.filter((task) => task.status === "done")
);

const isTaskDone = (task) => task.status === "done";

const getFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

const changeTaskStatus = (task) => {
  console.log("task logging:", task);

  let payload = {
    treatment: task.task_name,
    amount: 0,
    date: getFormattedDate(),
  };

  try {
    createMaintenanceLog(payload).then(() => {
      emit("logCreated");
    });
    // TODO: Notification or Route
  } catch (error) {
    console.log("Error:", error);
  }


};

onMounted(async () => {
  navigator.geolocation.getCurrentPosition(
    (position) => {
      let longitude = position.coords.longitude;
      let latitude = position.coords.latitude;

      getTasks(longitude, latitude).then((res) => {
        tasks.value = res.tasks;
      });
    },
    (error) => {
      console.error("Error getting location: ", error);
    }
  );
});
</script>
