<template>
  <div class="flex flex-col gap-6">
    <div class="flex flex-row flex-wrap gap-5 w-full">
      <div class="flex flex-col gap-6 max-w-[910px] w-full">
        <div class="flex flex-col w-full gap-6">
          <h3 class="subTitle">Overview</h3>

          <div class="grid grid-cols-4 gap-6 w-full flex-wrap">
            <OverviewCard 
              title="Days Until Rain" 
              :days="daysUntilRain === -1 ? null : (daysUntilRain > 7 ? 'More Than 7' : daysUntilRain)" 
              :icon="daysUntilRain === -1 ? loaderIcon : (daysUntilRain > 7 ? null : rainIcon)"
            />
            <OverviewCard
              title="Last Fertilization"
              :days="fertValue === -1 ? null : fertValue"
              :icon="fertValue === -1 ? loaderIcon : fertilizerIcon"
            />
            <OverviewCard 
              title="Last Pre-emergent" 
              :days="preEmValue === -1 ? null : preEmValue" 
              :icon="preEmValue === -1 ? loaderIcon : rainIcon" 
            />
            <OverviewCard
              title="Last Fertilization"
              days="08"
              :icon="fertilizerIcon"
            />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-6 flex-wrap justify-between">
          <div
            class="flex flex-col col-span-2 sm:col-span-1 gap-6 w-full"
            v-if="commonWeeds.length"
          >
            <h3 class="subTitle">Top Weeds in Yard</h3>

            <div
              class="flex flex-col gap-5 w-full"
              v-for="weed in commonWeeds?.slice(0, 3)"
              :key="weed.id"
            >
              <WeedCard
                :title="weed.common_name"
                :quantity="weed.scientific_name"
                :icon="weed.image_url"
                @click="() => router.push('/details/' + weed.weed_id)"
              />
            </div>
          </div>

          <div class="col-span-2 sm:col-span-1" v-if="weather">
            <h3 class="subTitle mb-6">Forecast</h3>
            <ForecastWidget :weather="weather"/>
          </div>
        </div>

        <FieldWidget />
      </div>

      <div class="max-w-[400px] w-full">
        <TasksWidget />
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRouter } from "vue-router";
import OverviewCard from "@/components/Dashboard/OverviewCard.vue";
import rainIcon from "@/assets/icons/rain.svg";
import fertilizerIcon from "@/assets/icons/fertilizer.svg";
import WeedCard from "@/components/Dashboard/WeedCard.vue";
import { getMostCommonWeeds } from "@/api/weeds";
import ForecastWidget from "@/components/Dashboard/ForecastWidget.vue";
import FieldWidget from "@/components/Dashboard/FieldWidget.vue";
import TasksWidget from "@/components/Dashboard/TasksWidget.vue";
import { getCurrentWeather } from "@/api/weather";
import { getDaysSinceFertilization } from "@/api/maintenance";

defineComponent({
  name: "DashboardView",
});

const router = useRouter();

let commonWeeds = ref([]);
const weather = ref(null);
let daysUntilRain = ref(-1);
let fertValue = ref(-1);
let preEmValue = ref(-1);

const findDaysUntilRainExceeds4 = (rainArray) => {
  for (let i = 0; i < rainArray.length; i++) {
    if (rainArray[i] > 4) {
      return i;
    }
  }
  return 8;
};

const calculateValue = (apiResponse) => {
  console.log('rain:', apiResponse.forecast_weekly.data.rain_sum);
  daysUntilRain.value = findDaysUntilRainExceeds4(apiResponse.forecast_weekly.data.rain_sum);
  console.log('DAYSTILRAIN:', daysUntilRain.value);
};

watch(weather, (newData) => {
  console.log('watch weather:', newData);
  calculateValue(newData);
});

onMounted(async () => {
  const weeds = await getMostCommonWeeds();
  commonWeeds.value = weeds.data;

  // Get days since last fertilization
  const fertData = await getDaysSinceFertilization();
  fertValue.value = Math.round(fertData.days_since_fertilization);
  preEmValue.value = Math.round(fertData.days_since_pre_emergent);

  navigator.geolocation.getCurrentPosition(
    (position) => {
      let longitude = position.coords.longitude;
      let latitude = position.coords.latitude;

      getCurrentWeather(longitude, latitude).then((data) => {
        console.log('current weather:', data);
        weather.value = data;
      });
    },
    (error) => {
      console.error("Error getting location: ", error);
    }
  );
});
</script>
