import { get, put } from "./axoisInstance";

export const getTasks = async (lng, lat) => {
  try {
    const response = await get("tasks/current", {
      lng,
      lat
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const updateTasks = async (id, data) => {
  try {
    const response = await put(`tasks/${id}/update`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
