import { post, get } from "./axoisInstance";

export const uploadPhoto = async (data) => {
  try {
    const response = await post("photos/upload", data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getPhotos = async () => {
  try {
    const response = await get("photos/user");
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
