import { get, remove } from "./axoisInstance";

export const getWeedById = async (id) => {
  try {
    const response = await get(`user-weeds/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const deleteWeedById = async (id) => {
  try {
    const response = await remove(`user-weeds/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getMostCommonWeeds = async () => {
  try {
    const response = await get(`most-common-weed`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
