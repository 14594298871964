<template>
  <div class="flex flex-col">
    <!-- Weed Identifier Section at the top -->
    <div
      v-if="weedsLoading"
      class="fixed top-0 bg-white/30 backdrop-blur-sm z-999 h-full w-full flex items-center justify-center"
    >
      <div class="flex items-center">
        <svg
          class="animate-spin h-8 w-8 text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    </div>

    <div class="p-3">
      <InputWeed :onUploadComplete="fetchIdentifiedWeeds" :loading="weedsLoading" />
    </div>

    <!-- List View -->

    <div class="bg-white rounded-t-md shadow">
      <ul class="divide-y divide-gray-200">
        <div
          v-for="userWeeds in weeds"
          :key="userWeeds.weeds_date"
          class="items-center p-3"
        >
          <div
            class="bg-gray-100 flex flex-row justify-between items-center p-3"
          >
            <h3 class="font-weight-bold">
              {{ formatDate(userWeeds.weeds_date) }}
            </h3>
            <RouterLink
              to="/treatment-plan"
              class="md:text-lg font-medium cursor-pointer text-blue-500 hover:text-blue-600"
            >
              Treatment Plan
            </RouterLink>
          </div>
          <li
            v-for="userWeed in userWeeds.weeds"
            :key="userWeed.id"
            class="flex items-center p-3 hover:bg-gray-50"
            @click="goToWeedDetails(userWeed.weed.id)"
          >
            <img
              :src="userWeed.weed.image_url"
              alt="Thumbnail"
              class="w-12 h-12 rounded-full mr-3"
            />
            <div class="flex-grow">
              <h2
                class="text-lg font-semibold hover:text-blue-500 cursor-pointer w-fit"
              >
                {{ userWeed.weed.common_name }}
              </h2>
              <div class="flex flex-row gap-2 items-center">
                <p class="text-sm">
                  {{ formatDate(userWeed.weed.created_at) }}
                </p>
              </div>
            </div>
            <span class="text-gray-500 hover:text-blue-500 cursor-pointer">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                ></path>
              </svg>
            </span>
          </li>
        </div>
      </ul>
    </div>
  </div>

  <v-snackbar
    v-model="toaster"
    :timeout="3000"
    location="top right"
    color="red"
    text-color="white"
    elevation="3"
  >
    Sorry, we aren’t able to identify!

    <template v-slot:actions>
      <v-btn color="white" variant="text" @click="toaster = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/authStore";
import { get } from "@/api/axoisInstance";
import InputWeed from "@/components/Weeds/InputWeed.vue";
import { formatDate } from "@/utils/date";
import { useRouter } from "vue-router";

export default {
  name: "WeedsView",
  components: {
    InputWeed,
  },
  setup() {
    const authStore = useAuthStore();
    const weeds = ref([]);
    const weedsLoading = ref(false);
    const toaster = ref(false);
    const router = useRouter();

    const fetchIdentifiedWeeds = async () => {
      weedsLoading.value = true;
      try {
        const response = await get(`/user-weeds`);
        const weeds_data = response.data?.reverse() || [];
        const grouped_weeds_data = [];

        for (let i = 0; i < weeds_data.length; i++) {
          const createddate = formatDate(weeds_data[i].created_at);

          let obj_index = grouped_weeds_data.findIndex(
            (o) => o.weeds_date === createddate
          );

          if (obj_index === -1) {
            grouped_weeds_data.push({
              weeds_date: createddate,
              weeds: [weeds_data[i]],
            });
          } else {
            grouped_weeds_data[obj_index].weeds.push(weeds_data[i]);
          }
        }

        weeds.value = grouped_weeds_data;
      } catch (error) {
        console.error("Error fetching identified weeds:", error);
      } finally {
        weedsLoading.value = false;
      }
    };

    onMounted(() => {
      if (authStore.isLoggedIn) {
        fetchIdentifiedWeeds();
      }
    });

  
    const goToWeedDetails = (weedId) => {
      router.push({ name: "WeedDetails", params: { id: weedId } });
    };

    return {
      weeds,
      weedsLoading,
      fetchIdentifiedWeeds,
      formatDate,
      toaster,
      goToWeedDetails
    };
  },
};
</script>

<style scoped>

.camera-button {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #4caf50; /* Green background */
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.camera-button svg {
  fill: #fff; /* White color for the icon */
}

.text-white {
  color: white;
}
</style>
