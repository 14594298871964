<template>
    <div class="min-h-screen flex flex-col justify-start items-center bg-gray-100 pt-20">
      <div class="container mx-auto px-4 py-10">
        <h1 class="text-3xl font-bold mb-8">Choose Your Plan</h1>
        <!-- Pricing Cards -->
        <div class="flex justify-center space-x-8">
          <!-- Pricing Card 1 -->
          <div class="p-8 bg-white rounded-lg shadow-md flex-grow">
            <h2 class="text-xl font-semibold mb-4">FREE</h2>
            <p class="text-gray-600 font-bold mb-4">No Cost</p>
            <ul class="text-gray-600 mb-6">
              <li>Lawn Task Management</li>
              <li>Basic Reporting</li>
              <li>Limited Support</li>
            </ul>
            <router-link to="/signup" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full block text-center">Get Started</router-link>
          </div>
  
          <!-- Pricing Card 2 - Highlighted -->
          <div class="p-8 bg-blue-500 text-white rounded-lg shadow-md flex-grow">
            <h2 class="text-xl font-semibold mb-4">Monthly</h2>
            <p class="text-white font-bold mb-4">$1 / month</p>
            <ul class="text-white mb-6">
              <li>All features included</li>
              <li>Advanced Reporting</li>
              <li>Premium Support</li>
            </ul>
            <router-link to="/signup" class="bg-white hover:bg-gray-200 text-blue-500 font-bold py-2 px-6 rounded-full block text-center">Get Started</router-link>
          </div>
  
          <!-- Pricing Card 3 -->
          <div class="p-8 bg-white rounded-lg shadow-md flex-grow">
            <h2 class="text-xl font-semibold mb-4">Yearly</h2>
            <p class="text-gray-600 font-bold mb-4">$10 / annual</p>
            <ul class="text-gray-600 mb-6">
              <li>All features included</li>
              <li>Advanced Reporting</li>
              <li>Premium Support</li>
            </ul>
            <router-link to="/signup" class="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-full block text-center">Get Started</router-link>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    // Component code
  };
  </script>
  
  <style scoped>
  /* Component-specific styles can go here */
  </style>
  