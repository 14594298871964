<template>
  <div>
    <!-- Sidebar -->
    <div
      class="shadow-2xl fixed lg:sticky inset-y-0 left-0 z-40 bg-primary-500 flex w-[288px] min-h-[100vh] flex-col gap-10 items-center py-[27px] transform transition-transform duration-300 ease-in-out"
      :class="isSidebarOpen ? 'translate-x-0' : '-translate-x-full'"
    >
      <!-- Close Icon -->
      <div
        class="absolute top-7 right-4 lg:hidden"
        @click="emit('toggleSidebar')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          class="w-8 h-8 text-white cursor-pointer"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </div>

      <!-- Sidebar Content -->
      <div
        class="flex flex-row gap-[10px] justify-start items-center w-[224px] pb-5 border-b border-b-primary-600"
      >
        <v-img :src="AppLogo" :max-width="32" :height="32" />
        <p class="font-bold text-[20px] leading-7 text-white">Turf Planner</p>
      </div>
      <div class="flex flex-col gap-2 max-h-full overflow-y-auto">
        <div v-for="link in links" :key="link.path" class="flex flex-col gap-2 h-full">
          <div
            @click="navigateTo(link.path)"
            class="flex flex-row gap-4 items-center justify-start h-[48px] p-3 text-white min-w-[224px] w-fit rounded hover:bg-primary-400 group cursor-pointer"
            :class="{
              'bg-white group-hover:text-black': $route.path === link.path,
            }"
          >
            <div>
              <v-img
                :src="$route.path === link.path ? link.activeIcon : link.icon"
                :width="30"
                :height="24"
                class="group-hover:fill-primary-400"
                :lazy-src="null"
              />
            </div>
            <p
              :class="{
                'text-primary-400 font-bold': $route.path === link.path,
              }"
            >
              {{ link.text }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, defineModel } from "vue";
import { useRouter } from "vue-router";
import DashboardIcon from "@/assets/sidebar/dashboard.svg";
import MaintenanceIcon from "@/assets/sidebar/maintenance.svg";
import JournalIcon from "@/assets/sidebar/photo-journal.svg";
import MyYardIcon from "@/assets/sidebar/my-yard.svg";
import WeedIcon from "@/assets/sidebar/weed.svg";
import { useAuthStore } from "@/stores/authStore";
import AppLogo from "@/assets/app/logo.svg";

import DashboardActiveIcon from "@/assets/sidebar/active/dashboard.svg";
import MaintenanceActiveIcon from "@/assets/sidebar/active/maintenance.svg";
import JournalActiveIcon from "@/assets/sidebar/active/photo-journal.svg";
import MyYardActiveIcon from "@/assets/sidebar/active/my-yard.svg";
import WeedActiveIcon from "@/assets/sidebar/active/weed.svg";
import { defineEmits } from "vue";

const router = useRouter();
const authStore = useAuthStore();

const emit = defineEmits(["toggleSidebar"]);

const isSidebarOpen = defineModel("isSidebarOpen", {
  type: Boolean,
  default: false,
});

const links = computed(() => {
  if (authStore.isLoggedIn) {
    return [
      {
        text: "Dashboard",
        path: "/dashboard",
        icon: DashboardIcon,
        activeIcon: DashboardActiveIcon,
      },
      {
        text: "Weed Identification",
        path: "/weeds",
        icon: WeedIcon,
        activeIcon: WeedActiveIcon,
      },
      {
        text: "My Yard",
        path: "/yard",
        icon: MyYardIcon,
        activeIcon: MyYardActiveIcon,
      },
      {
        text: "Maintenance",
        path: "/maintenance",
        icon: MaintenanceIcon,
        activeIcon: MaintenanceActiveIcon,
      },
      {
        text: "Photo Journal",
        path: "/photo-journal",
        icon: JournalIcon,
        activeIcon: JournalActiveIcon,
      },
    ];
  } else {
    return [
      { text: "Home", path: "/" },
      { text: "Pricing", path: "/pricing" },
      { text: "Signup", path: "/signup" },
      { text: "Login", path: "/login" },
    ];
  }
});

const navigateTo = (path) => {
  router.push(path);
  if (window.innerWidth < 1024) {
    isSidebarOpen.value = false;
  }
};
</script>
