<template>
  <div class="p-4 mt-10 w-full flex content-center flex-col gap-4 items-center">
    <AppButton @click="$router.go(-1)"> Go Back </AppButton>
    <div
      v-if="weed"
      class="mx-auto p-8 bg-gradient-to-br from-green-100 to-green-50 shadow-2xl rounded-lg"
    >
      <div
        class="flex flex-col lg:flex-row items-center lg:items-start lg:space-x-8"
      >
        <img
          :src="weed.image_url"
          alt="Weed Image"
          class="w-48 h-48 rounded-lg shadow-lg mb-6 lg:mb-0 transform hover:scale-105 transition duration-300"
        />
        <div class="text-center lg:text-left">
          <h1 class="text-4xl font-extrabold text-green-800">
            {{ weed.common_name }}
          </h1>
          <h2 class="text-2xl text-green-600 italic mt-2">
            {{ weed.scientific_name }}
          </h2>
        </div>
      </div>

      <div class="mt-10">
        <h3 class="text-2xl font-bold text-gray-800 mb-2">Description</h3>
        <p class="text-lg text-gray-700 leading-relaxed">
          {{ weed.description }}
        </p>
      </div>

      <div class="mt-8">
        <h3 class="text-2xl font-bold text-gray-800 mb-2">Habitat</h3>
        <p class="text-lg text-gray-700 leading-relaxed">{{ weed.habitat }}</p>
      </div>

      <div class="mt-8">
        <h3 class="text-2xl font-bold text-gray-800 mb-2">Control Measures</h3>
        <p class="text-lg text-gray-700 leading-relaxed">
          {{ weed.control_measures }}
        </p>
      </div>
    </div>
    <AppButton v-if="weed" @click="showWeedDeleteDialog = true" variant="danger"
      >Delete</AppButton
    >

    <AppModal
      :is-visible="showWeedDeleteDialog"
      title="Delete Weed"
      @confirm="deleteWeed"
      @close="showWeedDeleteDialog = false"
    >
      <p>Are you sure you want to delete this weed?</p>
    </AppModal>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import AppButton from "@/components/Base/AppButton.vue";
import AppModal from "@/components/Base/AppModal.vue";
import { getWeedById, deleteWeedById } from "@/api/weeds";
import { useRouter } from "vue-router";

export default {
  name: "WeedDetails",
  setup() {
    const route = useRoute();
    const weed = ref(null);
    const weed_id = ref(null);
    const showWeedDeleteDialog = ref(false);
    const id = route.params.id;
    const router = useRouter();

    const fetchWeedDetails = () => {
      getWeedById(id)
        .then((response) => {
          if (response.id) {
            console.log("response.weed;", response);
            weed.value = response.weed;
            weed_id.value = response.id;
          }
        })
        .catch((error) => {
          console.error("Error fetching weed details:", error);
        });
    };

    const deleteWeed = () => {
      deleteWeedById(weed_id.value)
        .then(() => {
          router.push({ name: "Weeds" });
        })
        .catch((error) => {
          console.error("Error deleting weed:", error);
        });
      showWeedDeleteDialog.value = false;
    };

    onMounted(() => {
      fetchWeedDetails();
    });

    return {
      weed,
      deleteWeed,
      showWeedDeleteDialog,
    };
  },
  components: {
    AppButton,
    AppModal,
  },
};
</script>
