<template>
  <button
    :class="[
      baseClass,
      variantClasses[variant],
      sizeClasses[size],
      { 'opacity-50 cursor-not-allowed': disabled },
    ]"
    :disabled="disabled"
    v-bind="attrs"
  >
    <slot></slot>
  </button>
</template>

<script setup>
import { useAttrs, defineProps } from 'vue';

defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => ['primary', 'secondary', 'success', 'danger', 'warning'].includes(value),
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => ['sm', 'md', 'lg'].includes(value),
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const attrs = useAttrs();

const baseClass = 'px-4 py-2 font-medium rounded-full w-fit hover:shadow-md';

const variantClasses = {
  primary: 'bg-primary-500 text-white hover:bg-primary-700',
  secondary: 'bg-white border border-secondary-500 text-black',
  success: 'bg-green-500 text-white hover:bg-green-700',
  danger: 'bg-red-500 text-white hover:bg-red-700',
  warning: 'bg-yellow-500 text-white hover:bg-yellow-700',
};

const sizeClasses = {
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
};
</script>
