<template>
  <div class="flex flex-col gap-4">
    <h1 class="heading">Measure</h1>
    <div class="grid gap-4">

      <!-- Map content card in the middle -->
      <div class="bg-white rounded-lg shadow p-5 flex flex-column gap-4">
        <h2 class="font-semibold text-lg">Yard Area Calculator</h2>
        <input type="text" v-model="address" placeholder="Enter address" class="w-full p-2 border rounded mb-2" />
        <AppButton @click="geocodeAddress">Go to Address</AppButton>
        <div id="map" ref="map" class="w-full h-80 mt-2"></div>
        <div id="info" class="mt-4">
          <p>{{ area }} sq ft</p>
        </div>

        <div class="flex flex-row gap-3">
          <AppButton @click="clearDrawing" variant="danger">Clear Drawing</AppButton>
          <AppButton @click="saveYard">Save Selected Area</AppButton>
        </div>

      </div>

    </div>
  </div>
</template>
  
  <script>
import { createYard, getYard } from '@/api/yard';
import AppButton from '@/components/Base/AppButton.vue';

  // eslint-disable-next-line no-unused-vars
  /* global google */
  export default {
    data() {
      return {
        map: null,
        drawingManager: null,
        selectedShape: null,
        area: 0,
        googleMapsLoaded: false,
        address: '',
        geocoder: null,
        points: null,
      };
    },
    mounted() {
      this.loadGoogleMapsApi();
      this.getYardDetails()
    },
    methods: {
      getYardDetails() {

        getYard().then((data) => {
          if (Array.isArray(data) && data.length > 0) {
            this.points = JSON.parse(data[0].location_points)
          }
        }).then(() => {
          this.drawDefaultPolygon()
        }).catch((error) => {
          console.log(error)
        })

      },
      loadGoogleMapsApi() {
        if (!window.google) {
          const script = document.createElement('script');
          script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDKgy02DjJjcZOhQmfmuAN3DkwkS6ChO5M&libraries=geometry,drawing,places`;
          script.async = true;
          script.defer = true;
          script.onload = () => {
            this.googleMapsLoaded = true;
            this.initializeMap();
          };
          document.head.appendChild(script);
        } else {
          this.googleMapsLoaded = true;
          this.initializeMap();
        }
      },
      initializeMap() {
        if (!this.googleMapsLoaded) return;
  
        this.map = new google.maps.Map(this.$refs.map, {
          center: { lat: 40.7128, lng: -74.0060 },
          zoom: 18,
          mapTypeId: 'satellite',
        });
  
        this.drawingManager = new google.maps.drawing.DrawingManager({
          drawingMode: google.maps.drawing.OverlayType.POLYGON,
          drawingControl: true,
          drawingControlOptions: {
            position: google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon'],
          },
          polygonOptions: {
            clickable: true,
            editable: true,
            zIndex: 1,
          },
        });
  
        this.geocoder = new google.maps.Geocoder();
  
        google.maps.event.addListener(this.drawingManager, 'overlaycomplete', (event) => {
          if (event.type === google.maps.drawing.OverlayType.POLYGON) {
            if (this.selectedShape) {
              this.selectedShape.setMap(null);
            }
            this.selectedShape = event.overlay;
            this.calculateArea();
            google.maps.event.addListener(this.selectedShape.getPath(), 'set_at', this.calculateArea);
            google.maps.event.addListener(this.selectedShape.getPath(), 'insert_at', this.calculateArea);
          }
        });
  
        this.drawingManager.setMap(this.map);
  
        // Get user's location
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              };
              this.map.setCenter(pos);
            },
            () => {
              console.error("Error fetching geolocation");
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      },
      calculateArea() {
        const area = google.maps.geometry.spherical.computeArea(this.selectedShape.getPath());
        this.area = (area * 10.7639).toFixed(2); // Convert square meters to square feet
      },
      clearDrawing() {
        if (this.selectedShape) {
          this.selectedShape.setMap(null);
          this.selectedShape = null;
          this.area = 0;
        }
      },
      geocodeAddress() {
        console.log('Geocoding Address');
        if (this.address === '') return;
        this.geocoder.geocode({ address: this.address }, (results, status) => {
          if (status === 'OK') {
            this.map.setCenter(results[0].geometry.location);
            this.map.setZoom(18);
          } else {
            alert('Geocode was not successful for the following reason: ' + status);
          }
        });
      },
      saveYard(){
        if(!this.selectedShape) return

        const path = this.selectedShape.getPath();
        const points = [];
        for (let i = 0; i < path.getLength(); i++) {
          const point = path.getAt(i);
          points.push({ latitude: point.lat(), longitude: point.lng() });
        }


        // Move this to MY YARD - TODO 
        let payload = {
          location_points : JSON.stringify(points),
          sqft: this.area
        }

        createYard(payload).then((data)=> {
          console.log(data)
        }).catch((error)=> {
          console.log(error)
        })
      },
      drawDefaultPolygon() {
      const defaultPolygonPath = this.points.map(point => new google.maps.LatLng(point.latitude, point.longitude));
      const defaultPolygon = new google.maps.Polygon({
        paths: defaultPolygonPath,
        clickable: true,
        editable: true,
        zIndex: 1,
      });

      defaultPolygon.setMap(this.map);
      this.selectedShape = defaultPolygon;
      this.calculateArea();

      // Fit map to the polygon bounds
      const bounds = new google.maps.LatLngBounds();
      defaultPolygonPath.forEach((point) => {
        bounds.extend(point);
      });
      this.map.fitBounds(bounds);

      google.maps.event.addListener(this.selectedShape.getPath(), 'set_at', this.calculateArea);
      google.maps.event.addListener(this.selectedShape.getPath(), 'insert_at', this.calculateArea);
    },
    },
    components: {
      AppButton,
    }
  };
  </script>
  
  <style scoped>
  #map {
    height: 80vh;
    width: 100%;
  }
  #info {
    padding: 20px;
  }
  button {
    cursor: pointer;
  }
  </style>
  