<template>
  <div
    class="flex flex-col h-full bg-primary-500 rounded justify-center gap-5 items-center"
  >
    <div v-if="!uploadedFile" class="flex flex-row gap-2">
      <input
        type="file"
        :accept="fileType"
        @change="handleFileInput"
        ref="fileInput"
        class="hidden"
      />
      <button
        @click="triggerFileInput"
        class="flex flex-row flex-wrap items-center"
      >
        <div>
          <v-icon size="80" icon="mdi-upload" color="white"></v-icon>
        </div>
        <span class="text-[20px] md:text-[30px] font-semibold text-white">{{
          buttonLabel
        }}</span>
      </button>
    </div>

    <div v-if="showFile && uploadedFile">
      <div v-if="isImage" class="max-w-[200px] max-h-[200px]">
        <img :src="filePreview" alt="Uploaded file" class="uploaded-preview" />
      </div>
      <div v-else>
        <p>{{ uploadedFile.name }}</p>
      </div>
    </div>

    <div v-if="action && uploadedFile" class="flex flex-row gap-2 items-center">
      <AppButton @click="handleUpload" variant="secondary">Upload</AppButton>
      <AppButton @click="handleClear" variant="danger">Clear</AppButton>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, defineProps } from "vue";
import AppButton from "./Base/AppButton.vue";

const props = defineProps({
  fileType: {
    type: String,
    default: "image/*",
  },
  showFile: {
    type: Boolean,
    default: true,
  },
  action: {
    type: Function,
    default: null,
  },
  buttonLabel: {
    type: String,
    default: "Select Photo",
  },
});

// Refs
const uploadedFile = ref(null);
const filePreview = ref(null);
const fileInput = ref(null);

// Computed properties to check file type
const isImage = computed(() => {
  return uploadedFile.value && uploadedFile.value.type.startsWith("image/");
});

const handleClear = () => {
  uploadedFile.value = null;
  filePreview.value = null;
};

// Methods
const handleFileInput = (event) => {
  const file = event.target.files[0];
  if (file) {
    uploadedFile.value = file;

    // Create a file preview if it's an image
    if (isImage.value) {
      filePreview.value = URL.createObjectURL(file);
    }
  }
};

const handleUpload = async () => {
  if (props.action && uploadedFile.value) {
    await props.action(uploadedFile.value);
  }
};

const triggerFileInput = () => {
  fileInput.value.click();
};
</script>
