<template>
  <div v-if="isVisible" class="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 !bg-opacity-50">
    <div class="bg-white rounded-lg shadow-lg p-4 m-2" :class="sizeClasses[size]">
      <div class="flex justify-between items-center">
        <h3 v-if="title" class="text-xl font-semibold">{{ title }}</h3>
        <button @click="closeModal" class="text-gray-600 text-2xl hover:text-gray-900">
          &times;
        </button>
      </div>
      <div class="mt-4">
        <slot></slot>
      </div>
      <div v-if="showFooter" class="mt-4 flex justify-end space-x-2">
        <AppButton v-if="cancelText" @click="closeModal"
          class="px-4 py-2 rounded bg-gray-500 text-white hover:bg-gray-700">
          {{ cancelText }}
        </AppButton>
        <AppButton v-if="confirmText" @click="confirmModal"
          class="px-4 py-2 rounded bg-blue-500 text-white hover:bg-blue-700">
          {{ confirmText }}
        </AppButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";
import AppButton from "./AppButton.vue";

defineProps({
  title: {
    type: String,
    default: "",
  },
  size: {
    type: String,
    default: "md",
    validator: (value) => ["sm", "md", "lg"].includes(value),
  },
  isVisible: {
    type: Boolean,
    default: false,
  },
  showFooter: {
    type: Boolean,
    default: true,
  },
  cancelText: {
    type: String,
    default: "Cancel",
  },
  confirmText: {
    type: String,
    default: "Confirm",
  },
});

const emit = defineEmits(["close", "confirm"]);

const sizeClasses = {
  sm: "w-[100%] max-w-[300px]",
  md: "w-[100%] max-w-[500px]",
  lg: "w-[100%] max-w-[700px]",
};

const closeModal = () => {
  emit("close");
};

const confirmModal = () => {
  emit("confirm");
};
</script>
