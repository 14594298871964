<template>
  <div class="text-center">
    <v-menu location="bottom">
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" icon class="bg-transparent !p-0 w-fit">
          <v-icon size="large" color="green">mdi-leaf</v-icon>
        </v-btn>      
      </template>

      <v-list>
        <v-list-item v-for="(item, index) in items" :key="index" @click="handleAction(item)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useAuthStore } from '@/stores/authStore';

const router = useRouter();
const authStore = useAuthStore();


const items = ref([
  { title: 'My Yard', route: '/yard' },
  { title: 'Settings', route: '/settings' },
  { title: 'Billing', route: '/billing' },
  { title: 'Logout', action: logout }
]);

function handleAction(item) {
  if (item.route) {
    router.push(item.route);
  } else if (item.action) {
    item.action();
  }
}

function logout() {
  console.log('Logging out');
  authStore.logout();
}
</script>
