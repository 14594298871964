<template>
  <div>
    <AppButton
      variant="primary"
      size="lg"
      class="w-[700px] bg-primary-500 rounded"
      @click="modalOpen"
    >
      <div class="flex flex-row items-center">
        <v-icon name="plus" icon="mdi-plus" class="mr-1" />
        Add Log
      </div>
    </AppButton>
  </div>

  <AppModal
    :isVisible="showModal"
    title="Log Maintenance"
    :showFooter="true"
    cancelText="Cancel"
    confirmText="Add"
    @close="handleClose"
    @confirm="handleConfirm"
    size="md"
  >
    <template #default>
      <Form>
        <div class="flex flex-col gap-5">
          <div>
            <label
              for="treatment-select"
              class="block mb-2 text-sm font-medium"
            >
              Treatment Options
            </label>
            <Field
              as="select"
              id="treatment-select"
              name="selectedProductName"
              class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            >
              <option
                v-for="option in productNames"
                :key="option"
                :value="option"
              >
                {{ option }}
              </option>
            </Field>
          </div>

          <div v-if="!excludeAmount.includes(values.selectedProductName)">
            <label for="amount" class="block mb-2 text-sm font-medium">
              Amount Applied
            </label>
            <Field
              id="amount"
              name="quantity"
              type="number"
              step="0.01"
              placeholder="Enter amount"
              class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            <ErrorMessage name="quantity" class="text-red-600" />
          </div>

          <div>
            <label for="date" class="block mb-2 text-sm font-medium">
              Date Applied
            </label>
            <Field
              id="date"
              name="dateApplied"
              type="date"
              class="mt-1 block w-full p-2 border border-gray-300 rounded-md"
            />
            <ErrorMessage name="dateApplied" class="text-red-600" />
          </div>
        </div>
      </Form>
    </template>
  </AppModal>
</template>

<script setup>
import { ref } from "vue";
import { useForm, Field, ErrorMessage } from "vee-validate";
import * as yup from "yup";
import AppButton from "../Base/AppButton.vue";
import AppModal from "../Base/AppModal.vue";
import { createMaintenanceLog } from "@/api/maintenance";
import { defineEmits } from "vue";

const emit = defineEmits(["logCreated"]);

const showModal = ref(false);
const productNames = [
  "Mowed grass",
  "Applied Pre-emergent",
  "Fertilized",
  "Watered",
  "Aerated",
  "Applied Fungicide",
  "Sprayed Weeds",
  "Scalped",
  "Seeded",
  "Applied Insecticide",
  "Treat for Army Worms",
  "Treat for Grubs",
  "Apply Disease Prevention",
  "Added Organic Matter",
  "Added Carbon - Humichar, Carbon-x",
  "PH Correction",
  "Lawn Leveling",
  "Applied Iron",
];

// Pre-emergent (Read Split Treatment)
// Fertilization 10-10-10
// GreenShocker (new)
// PGF Complete Fertilizer
// Super Juice Supplement
// Fall Army Worms (Treat only if seen)
// Grubs (Treat if detected)
// General Disease (Both prevent and cure)
// HUMICHAR™ Soil Treatment
// Spring Scalp (Green haze ALL over lawn)
// Aeration
// Lawn Leveling
// PH Corrections
// Organic Matter / DirtBooster
// Seeding Warm Season
// Weed Sprays

const excludeAmount = ["Mowed grass", "Aerated", "Sprayed Weeds"];

const validationSchema = yup.object({
  quantity: yup.number().when("selectedProductName", {
    is: (value) => !excludeAmount.includes(value),
    then: (schema) =>
      schema.required("Please enter the amount applied.").positive("Amount must be positive."),
    otherwise: (schema) => schema.notRequired(),
  }),
  dateApplied: yup
    .date()
    .nullable() // Allows null or undefined values
    .transform((value, originalValue) => (originalValue === "" ? null : value))
    .required("Please select a date."),
});

const { values, handleSubmit, resetForm } = useForm({
  validationSchema,
  initialValues: {
    selectedProductName: productNames[0],
    quantity: null,
    dateApplied: new Date().toISOString().split("T")[0],
  },
});

const handleClose = () => {
  showModal.value = false;
  resetForm();
};

const handleConfirm = handleSubmit(async (formValues) => {
  showModal.value = false;

  let payload = {
    treatment: formValues.selectedProductName,
    ...(formValues.quantity ? { amount: formValues.quantity } : {}),
    date: formValues.dateApplied,
  };

  try {
    await createMaintenanceLog(payload).then(() => {
      emit("logCreated");
    });
    // TODO: Notification or Route
  } catch (error) {
    console.log("Error:", error);
  }
});

const modalOpen = () => {
  showModal.value = true;
  resetForm();
};
</script>
